<template lang="pug">
component(:is='to ? "router-link" : "div"' :to='to' :class='"list-item--" + mode' class='list-item')
  div(class='list-item__inner')
    div(v-if='$slots.icon' class='list-item__icon')
      slot(name='icon')
    div(class='list-item__content')
      slot
      div(class='list-item__title')
        span(class='list-item__titleText')
          slot(name='title')
        span(class='list-item__titleAfter')
          slot(name='titleAfter')
      div(class='list-item__belowTitle')
        slot(name='belowTitle')
    div(v-if='$slots.actions' class='list-item__actions')
      slot(name='actions')
</template>

<script lang="ts">
import { defineComponent, type PropType } from 'vue'
import { type RouteLocationRaw } from 'vue-router'

const ListItem = defineComponent({
  props: {
    to: Object as PropType<RouteLocationRaw>,
    mode: { type: String as PropType<'default' | 'hover' | 'floating'>, default: 'default' },
  },
})
export default ListItem
</script>
<style lang="stylus">
@import '../styles/variables.styl'
:root
  --list-item-gap: 16px
  --list-item-bg: transparent
  --list-item-icon-color: var(--text)
  --list-item-bg-highlighted: var(--purple-50)
  --list-item-bg-highlighted-hover: var(--purple-100)
.list-item
  --list-item-padding: 15px var(--bleed-right, 15px) 15px var(--bleed-left, 15px)
  text-align: left
  position: relative
  background: var(--list-item-bg)
  border: none
  padding: var(--list-item-padding)
  text-decoration: none
  display: flex
  appearance: none
  width: 100%
  &:hover, &:focus
    text-decoration: none
    --list-item-bg: var(--grey-50)
  &[data-highlighted="true"]
    --list-item-bg: var(--list-item-bg-highlighted)
    &:hover, &:focus
      --list-item-bg: var(--list-item-bg-highlighted-hover)
  &__inner
    width: 100%
    display: flex
    gap: var(--list-item-gap)
  &__content
    flex-grow: 1
    min-width: 0
  &__actions
    display: flex
    align-items: center
  &--floating &__actions
    position: absolute
    right: 0
    top: 0
    padding: 5px
  &--floating &__actions,
  &--hover &__actions
    opacity: 0
    ~/:hover&
      opacity: 1
  &__actions
    padding-left: 5px
  &__titleAfter
    margin-left: 7px
    flex-grow: 1
    &:empty
      display: none
  &__title,
  &__belowTitle
    display: flex
    &:empty
      display: none
  &__title
    align-items: center
  &__titleText
    overflow: hidden
    white-space: nowrap
    text-overflow: ellipsis
    font-weight: 600
    justify-content: baseline
    color: var(--color-text)
    ~/:focus &, ~/:hover &
      text-decoration: underline
</style>
